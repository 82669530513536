'use client';

import React from 'react';
import useAuthenticatedUser from '@dr-pam/common-components/Hooks/useAuthenticatedUser';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

export type AuthLinkProps = {
	className?: string;
	onClick?: () => void;
};
export default function AuthLinks(props: AuthLinkProps) {
	const { className, onClick } = props;
	const { user, isLoading } = useAuthenticatedUser();
	const pathname = usePathname();

	let link = `/login?r=${encodeURI(pathname)}`;
	let linkClassName = 'login-link';
	let linkText = 'login / sign up';

	if (user) {
		link = '/account';
		linkClassName = 'account-link';
		linkText = 'my account';
	}

	return (
		<Link className={`auth-links ${linkClassName} ${className ?? ''}`} href={link} onClick={onClick}>
			<span>{isLoading ? 'Loading...' : linkText}</span>
		</Link>
	);
}
